import React, { useState, useEffect, useRef } from "react";
import { cn, Content, InlineText, Tooltip, useFormattingLanguage } from "@siteimprove/fancylib";
import { Popover } from "../../overlay/popover/popover";
import { toFormattedNumberString } from "../../text/formatted-number/formatted-number";
import { useLabTranslations } from "../../../translations/translations";
import { colorFromPercentageRedToGreenScale } from "../../../utils/color-utils";
import * as scss from "./circular-progress.scss";

export type CircularProgressProps = {
	/* Title of this gauge data visualization */
	title: string;
	/* Value of current DCI score */
	scoreValue: number;
	/** Label for the gauge */
	"aria-label"?: string;
	/** Values for the circular progress component animation */
	delta?: { value: number; tooltipText: string; ariaLabel: string };
	/** Value for the industry benchmark score */
	benchmark?: ValueAndTooltipProps;
	/** Value for the user set target score */
	target?: ValueAndTooltipProps;
};

export type ValueAndTooltipProps = {
	value: number;
	tooltipContent: React.ReactChild;
	tooltipAriaLabel: string;
};

export type CircularProgressTooltipProps = {
	/** Text for the circular progress gauge tooltip */
	content: React.ReactChild;
	/** Label for the circular progress gauge tooltip */
	"aria-label": string;
	/** Value for the tooltip top placement */
	top: number;
	/** Value for the tooltip right placement */
	right: number;
	/** Value for the tooltip bottom placement */
	bottom: number;
	/** Value for the tooltip left placement */
	left: number;
	/** If true, tooltip will show */
	active: boolean;
	/** Event for when tooltip is clicked by mouse */
	onMouseEnter: () => void;
	/** Event for when tooltip is un-clicked by mouse */
	onMouseLeave: () => void;
};

interface Coordinates {
	x: number;
	y: number;
}

interface BenchmarkColors {
	stroke: string;
	fill: string;
}

export function CircularProgress(props: CircularProgressProps): JSX.Element {
	const { benchmark, target, scoreValue } = props;
	const strokeWidth = 1.5;
	const radius = 6;
	const size = radius * 2 + strokeWidth * 2;
	const prefersReducedMotion =
		typeof matchMedia !== "undefined" && matchMedia("(prefers-reduced-motion)").matches;

	const initialScore = useRef(scoreValue);
	const [variedAnimationDuration, setVariedAnimationDuration] = useState(1200);
	useEffect(() => {
		setVariedAnimationDuration(initialScore.current === scoreValue ? 1200 : 600);
	}, [scoreValue]);

	// Score
	const scoreAnimationDelay = prefersReducedMotion ? 0 : parseInt(scss.scoreAnimationDelay);
	const scoreAnimationDuration = prefersReducedMotion ? 1 : variedAnimationDuration;
	const scoreRadius = 43;
	const scoreValueInteger: number = Math.min(scoreValue, 100);
	// Max 359 degrees. 360 will become 0.
	const scoreEndAngle: number = Math.max(Math.min((scoreValueInteger * 360) / 100, 359), 0);
	const scoreStroke = colorFromPercentageRedToGreenScale(scoreValueInteger);

	// Benchmark
	const benchmarkAnimationDelay = prefersReducedMotion ? 0 : parseInt(scss.benchmarkAnimationDelay);
	const benchmarkAnimationDuration = prefersReducedMotion ? 1 : variedAnimationDuration;
	const benchmarkValue = Math.min(benchmark ? benchmark.value : 0, 100);
	// Max 359 degrees. 360 will become 0.
	let benchmarkEndAngle = Math.min((benchmarkValue * 360) / 100, 359);
	const benchmarkStroke =
		benchmarkValue > scoreValueInteger ? scss.benchmarkStrokeInitial : scoreStroke;

	const [isBenchmarkTooltipActive, setIsBenchmarkTooltipActive] = useState(false);

	// Target
	const targetAnimationDelay = prefersReducedMotion ? 0 : parseInt(scss.targetAnimationDelay);
	const targetAnimationDuration = prefersReducedMotion ? 1 : variedAnimationDuration;
	// Max 359 degrees. 360 will become 0.
	let targetEndAngle = Math.min((Math.min(target ? target.value : 0, 100) * 360) / 100, 359);

	const [isTargetTooltipActive, setIsTargetTooltipActive] = useState(false);

	// Handle overlapping benchmark and target values
	// to display tooltips without interfering each other.
	if (props.target && props.benchmark) {
		const offset = Math.abs(props.benchmark.value - props.target.value);
		if (offset <= 5) {
			const offsetAngle = (((5 - offset) / 2) * 360) / 100;
			if (props.benchmark.value > props.target.value) {
				benchmarkEndAngle = benchmarkEndAngle + offsetAngle;
				targetEndAngle = targetEndAngle - offsetAngle;
			} else {
				benchmarkEndAngle = benchmarkEndAngle - offsetAngle;
				targetEndAngle = targetEndAngle + offsetAngle;
			}
		}
	}

	const benchmarkCoordinates = polarToCartesian(50, 50, benchmarkEndAngle, scoreRadius);
	const benchmarkTooltipCoordinates: Coordinates = {
		x: benchmarkCoordinates.x - size / 2.0,
		y: benchmarkCoordinates.y - size / 2.0,
	};

	const targetCoordinates = polarToCartesian(50, 50, targetEndAngle, scoreRadius);
	const targetTooltipCoordinates: Coordinates = {
		x: targetCoordinates.x - size / 2.0,
		y: targetCoordinates.y - size / 2.0,
	};

	const i18n = useLabTranslations();

	const options: CircularProgressAnimationProps = {
		title: props.title,
		"aria-label": props["aria-label"] ?? i18n.dciScoreGauge,
		delta: props.delta,
		score: {
			scoreValueInteger,
			scoreEndAngle,
			options: {
				scoreRadius,
				scoreStroke: scoreStroke,
				scoreAnimationDuration,
				scoreAnimationDelay,
			},
		},
		benchmark: {
			hasBenchmark: props.benchmark !== undefined && props.benchmark.value > 0,
			benchmarkValue,
			benchmarkEndAngle,
			options: {
				radius,
				strokeWidth,
				benchmarkStroke,
				benchmarkAnimationDuration,
				benchmarkAnimationDelay,
			},
			tooltip: {
				content: props.benchmark?.tooltipContent as React.ReactChild,
				"aria-label": props.benchmark?.tooltipAriaLabel ?? "",
				left: benchmarkTooltipCoordinates.x,
				top: benchmarkTooltipCoordinates.y,
				right: 100 - benchmarkTooltipCoordinates.x - size,
				bottom: 100 - benchmarkTooltipCoordinates.y - size,
				active: isBenchmarkTooltipActive,
				onMouseEnter: () => setIsBenchmarkTooltipActive(true),
				onMouseLeave: () => setIsBenchmarkTooltipActive(false),
			},
		},
		target: {
			hasTarget: props.target !== undefined && props.target.value > 0,
			targetValue: props.target?.value,
			targetEndAngle,
			options: {
				targetAnimationDuration,
				targetAnimationDelay,
				targetAnimationAlpha: 0,
			},
			tooltip: {
				content: props.target?.tooltipContent as React.ReactChild,
				"aria-label": props.target?.tooltipAriaLabel ?? "",
				left: targetTooltipCoordinates.x,
				top: targetTooltipCoordinates.y,
				right: 100 - targetTooltipCoordinates.x - size,
				bottom: 100 - targetTooltipCoordinates.y - size,
				active: isTargetTooltipActive,
				onMouseEnter: () => setIsTargetTooltipActive(true),
				onMouseLeave: () => setIsTargetTooltipActive(false),
			},
		},
	};

	return <CircularProgressAnimation {...options} />;
}

interface CircularProgressAnimationProps {
	title: string;
	"aria-label": string;
	delta?: { value: number; tooltipText: string; ariaLabel: string };
	score: {
		scoreValueInteger: number;
		scoreEndAngle: number;
		options: {
			scoreRadius: number;
			scoreStroke: string;
			scoreAnimationDuration: number;
			scoreAnimationDelay: number;
		};
	};
	benchmark: {
		hasBenchmark: boolean;
		benchmarkValue: number;
		benchmarkEndAngle: number;
		options: {
			radius: number;
			strokeWidth: number;
			benchmarkStroke: string;
			benchmarkAnimationDuration: number;
			benchmarkAnimationDelay: number;
		};
		tooltip: CircularProgressTooltipProps;
	};
	target: {
		hasTarget: boolean;
		targetValue?: number;
		targetEndAngle: number;
		options: {
			targetAnimationDuration: number;
			targetAnimationDelay: number;
			targetAnimationAlpha: number;
		};
		tooltip: CircularProgressTooltipProps;
	};
}

function CircularProgressAnimation(props: CircularProgressAnimationProps): JSX.Element {
	const {
		score: {
			scoreValueInteger,
			scoreEndAngle,
			options: { scoreRadius, scoreAnimationDuration, scoreAnimationDelay },
		},
		benchmark: {
			hasBenchmark,
			benchmarkEndAngle,
			options: { benchmarkAnimationDuration, benchmarkAnimationDelay },
		},
		target: {
			hasTarget,
			targetEndAngle,
			options: { targetAnimationDuration, targetAnimationDelay },
		},
	} = props;

	const initialScoreVisualState =
		scoreValueInteger !== 0 ? VisualState.FADE_IN : VisualState.UNDEFINED;
	const scoreRef = useRef({
		startAngle: 0,
		endAngle: scoreEndAngle,
		value: scoreValueInteger,
		startVisualState: initialScoreVisualState,
		endVisualState: initialScoreVisualState,
	});

	const initialBenchmarkVisualState = hasBenchmark ? VisualState.FADE_IN : VisualState.UNDEFINED;
	const benchmarkRef = useRef({
		startAngle: 0,
		endAngle: benchmarkEndAngle,
		startVisualState: initialBenchmarkVisualState,
		endVisualState: initialBenchmarkVisualState,
	});

	const initialTargetVisualState = hasTarget ? VisualState.FADE_IN : VisualState.UNDEFINED;
	const targetRef = useRef({
		startAngle: 0,
		endAngle: targetEndAngle,
		startVisualState: initialTargetVisualState,
		endVisualState: initialTargetVisualState,
	});

	const animationDuration = Math.max(
		scoreAnimationDelay + scoreAnimationDuration,
		benchmarkAnimationDelay + benchmarkAnimationDuration,
		targetAnimationDelay + targetAnimationDuration
	);

	const [scoreAlpha, setScoreAlpha] = useState(0);
	const [benchmarkAlpha, setBenchmarkAlpha] = useState(0);
	const [targetAlpha, setTargetAlpha] = useState(0);
	const [animationTrigger, setAnimationTrigger] = useState(0);

	const [angle, setAngle] = useState(0);
	const [score, setScore] = useState(0);
	const [arcStroke, setArcStroke] = useState<string>(colorFromPercentageRedToGreenScale(0));
	const [scoreVisualState, setScoreVisualState] = useState<VisualState>(VisualState.UNDEFINED);

	const callback: (elapsed: number) => void = (elapsed: number) => {
		setScoreAlpha(
			easeInOutQuart(
				Math.min(Math.max((elapsed - scoreAnimationDelay) / scoreAnimationDuration, 0), 1)
			)
		);
		setBenchmarkAlpha(
			easeInOutQuart(
				Math.min(Math.max((elapsed - benchmarkAnimationDelay) / benchmarkAnimationDuration, 0), 1)
			)
		);
		setTargetAlpha(
			easeInOutQuart(
				Math.min(Math.max((elapsed - targetAnimationDelay) / targetAnimationDuration, 0), 1)
			)
		);
	};

	// Use useRef for mutable variables that we want to persist
	// without triggering a re-render on their change
	const requestRef = useRef<number>(0);
	const previousTimeRef = useRef<number>(0);
	const elapsed = useRef<number>(0);
	const firstRender = useRef(true);

	const animate = (time: number) => {
		if (previousTimeRef.current !== 0) {
			elapsed.current = elapsed.current + time - previousTimeRef.current;
			if (elapsed.current < animationDuration) {
				callback(elapsed.current);
			}
		}

		if (elapsed.current >= animationDuration) {
			callback(animationDuration);
			cancelAnimationFrame(requestRef.current);
			// Request animation if there are new values.
			setAnimationTrigger(animationTrigger + 1);
		} else {
			previousTimeRef.current = time;
			requestRef.current = requestAnimationFrame(animate);
		}
	};

	useEffect(() => {
		if (firstRender.current) {
			// Initial animation.
			firstRender.current = false;
			requestRef.current = requestAnimationFrame(animate);
		} else if (scoreAlpha === 1 && benchmarkAlpha === 1 && targetAlpha === 1) {
			let requestAnimation = false;

			const hasScore = scoreValueInteger !== 0;

			const scoreStartVisualState =
				hasScore || scoreRef.current.startAngle !== 0 ? VisualState.FADE_IN : VisualState.UNDEFINED;

			const scoreEndVisualState = hasScore ? VisualState.FADE_IN : VisualState.FADE_OUT;

			if (scoreRef.current.endAngle !== scoreEndAngle) {
				scoreRef.current = {
					...getRenderingValues(
						scoreRef.current.endAngle,
						scoreEndAngle,
						scoreStartVisualState,
						scoreEndVisualState
					),
					value: scoreValueInteger,
				};
				requestAnimation = true;
				setScoreAlpha(0);
			} else {
				// Update reference with the last value.
				scoreRef.current = {
					...getRenderingValues(
						scoreEndAngle,
						scoreEndAngle,
						scoreStartVisualState,
						scoreEndVisualState
					),
					value: scoreValueInteger,
				};
			}

			const benchmarkStartVisualState =
				hasBenchmark || benchmarkRef.current.startAngle !== 0
					? VisualState.FADE_IN
					: VisualState.UNDEFINED;

			const benchmarkEndVisualState = hasBenchmark
				? VisualState.FADE_IN
				: benchmarkRef.current.startAngle !== 0
				? VisualState.FADE_OUT
				: VisualState.UNDEFINED;

			if (benchmarkRef.current.endAngle !== benchmarkEndAngle) {
				benchmarkRef.current = getRenderingValues(
					benchmarkRef.current.endAngle,
					benchmarkEndAngle,
					benchmarkStartVisualState,
					benchmarkEndVisualState
				);
				requestAnimation = true;
				setBenchmarkAlpha(0);
			} else {
				// Update reference with the last value.
				benchmarkRef.current = getRenderingValues(
					benchmarkEndAngle,
					benchmarkEndAngle,
					benchmarkStartVisualState,
					benchmarkEndVisualState
				);
			}

			const targetStartVisualState =
				hasTarget || targetRef.current.startAngle !== 0
					? VisualState.FADE_IN
					: VisualState.UNDEFINED;

			const targetEndVisualState = hasTarget
				? VisualState.FADE_IN
				: targetRef.current.startAngle !== 0
				? VisualState.FADE_OUT
				: VisualState.UNDEFINED;

			if (targetRef.current.endAngle !== targetEndAngle) {
				targetRef.current = getRenderingValues(
					targetRef.current.endAngle,
					targetEndAngle,
					targetStartVisualState,
					targetEndVisualState
				);
				requestAnimation = true;
				setTargetAlpha(0);
			} else {
				// Update reference with the last value.
				targetRef.current = getRenderingValues(
					targetEndAngle,
					targetEndAngle,
					targetStartVisualState,
					targetEndVisualState
				);
			}

			if (requestAnimation) {
				elapsed.current = 0;
				previousTimeRef.current = 0;
				requestRef.current = requestAnimationFrame(animate);
			}
		}
	}, [
		scoreValueInteger,
		props.benchmark?.benchmarkValue,
		props.target?.targetValue,
		animationTrigger,
	]);

	useEffect(() => {
		const currentAngle = Math.min(
			(scoreRef.current.endAngle - scoreRef.current.startAngle) * scoreAlpha +
				scoreRef.current.startAngle,
			359
		);
		setAngle(currentAngle);

		if (scoreAlpha !== 1.0) {
			if (scoreRef.current.startAngle !== scoreRef.current.endAngle) {
				const currentScore = Math.floor((currentAngle * 100) / 360);
				setScore(currentScore);
				setArcStroke(colorFromPercentageRedToGreenScale(currentScore));
			}
			setScoreVisualState(scoreRef.current.startVisualState);
		} else {
			// Last iteration.
			setScore(scoreRef.current.value);
			setArcStroke(colorFromPercentageRedToGreenScale(scoreRef.current.value));
			setScoreVisualState(scoreRef.current.endVisualState);
		}
	}, [scoreAlpha]);

	return (
		<div
			className={cn(scss.circularGaugeContainer, scss.circularGaugeDefaultSize)}
			role="group"
			aria-label={props["aria-label"]}
		>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				version="1.1"
				viewBox="0 0 100 100"
				focusable={false}
				aria-hidden="true"
				className={scss.circularGaugeSvg}
			>
				<circle
					className={scss.backgroundCircle}
					cx="50"
					cy="50"
					r={scoreRadius}
					strokeWidth="4"
					fill="none"
				/>
				<TargetProgress
					scoreRadius={props.score.options.scoreRadius}
					startAngle={targetRef.current.startAngle}
					endAngle={targetRef.current.endAngle}
					animationAlpha={targetAlpha}
				/>
				<ScoreProgress
					angle={angle}
					arcStroke={arcStroke}
					scoreRadius={scoreRadius}
					visualState={scoreVisualState}
				/>
				<Benchmark
					scoreRadius={props.score.options.scoreRadius}
					startAngle={benchmarkRef.current.startAngle}
					endAngle={benchmarkRef.current.endAngle}
					radius={props.benchmark.options.radius}
					strokeWidth={props.benchmark.options.strokeWidth}
					stroke={props.benchmark.options.benchmarkStroke}
					animationAlpha={benchmarkAlpha}
					tooltipActive={props.benchmark.tooltip.active}
					startVisualState={benchmarkRef.current.startVisualState}
					endVisualState={benchmarkRef.current.endVisualState}
				/>
				<TargetScope
					startAngle={targetRef.current.startAngle}
					endAngle={targetRef.current.endAngle}
					animationAlpha={targetAlpha}
					startVisualState={targetRef.current.startVisualState}
					endVisualState={targetRef.current.endVisualState}
				/>
			</svg>
			<CircularProgressContent title={props.title} delta={props.delta} scoreValue={score} />
			{hasBenchmark && (
				<ContentTooltip
					content={props.benchmark.tooltip.content}
					aria-label={props.benchmark.tooltip["aria-label"]}
					top={props.benchmark.tooltip.top}
					right={props.benchmark.tooltip.right}
					bottom={props.benchmark.tooltip.bottom}
					left={props.benchmark.tooltip.left}
					active={props.benchmark.tooltip.active}
					onMouseEnter={props.benchmark.tooltip.onMouseEnter}
					onMouseLeave={props.benchmark.tooltip.onMouseLeave}
				/>
			)}
			{hasTarget && (
				<ContentTooltip
					content={props.target.tooltip.content}
					aria-label={props.target.tooltip["aria-label"]}
					top={props.target.tooltip.top}
					right={props.target.tooltip.right}
					bottom={props.target.tooltip.bottom}
					left={props.target.tooltip.left}
					active={props.target.tooltip.active}
					onMouseEnter={props.target.tooltip.onMouseEnter}
					onMouseLeave={props.target.tooltip.onMouseLeave}
				/>
			)}
		</div>
	);
}

function getRenderingValues(
	startAngle: number,
	endAngle: number,
	startVisualState: VisualState,
	endVisualState: VisualState
) {
	return {
		startAngle,
		endAngle,
		startVisualState,
		endVisualState,
	};
}

enum VisualState {
	UNDEFINED = "UNDEFINED",
	FADE_IN = "FADE_IN",
	FADE_OUT = "FADE_OUT",
	HIDDEN = "HIDDEN",
}

interface ScoreProgressProps {
	angle: number;
	arcStroke: string;
	scoreRadius: number;
	visualState: VisualState;
}

function ScoreProgress(props: ScoreProgressProps): JSX.Element {
	const { angle, scoreRadius, arcStroke } = props;

	const [pathData, setPathData] = useState("");

	useEffect(() => {
		setPathData(describeArc(50, 50, 0, angle, scoreRadius));
	}, [angle]);

	return (
		<path
			className={cn(
				scss.scoreProgress,
				scss.strokeTransition,
				props.visualState === VisualState.FADE_IN && scss.fadeIn,
				props.visualState === VisualState.FADE_OUT && scss.fadeOut
			)}
			d={pathData}
			fill="none"
			stroke={arcStroke}
			strokeLinecap="round"
			strokeWidth="6"
		/>
	);
}

interface BenchmarkProps {
	scoreRadius: number;
	startAngle: number;
	endAngle: number;
	radius: number;
	strokeWidth: number;
	stroke: string;
	animationAlpha: number;
	tooltipActive: boolean;
	startVisualState: VisualState;
	endVisualState: VisualState;
}

function Benchmark(props: BenchmarkProps): JSX.Element {
	const {
		scoreRadius,
		startAngle,
		endAngle,
		radius,
		strokeWidth,
		stroke,
		animationAlpha,
		tooltipActive,
	} = props;

	const [benchmarkCoordinates, setBenchmarkCoordinates] = useState<Coordinates>({
		x: 50,
		y: strokeWidth + radius,
	});

	const [benchmarkColors, setBenchmarkColors] = useState<BenchmarkColors>({
		stroke: scss.benchmarkStrokeInitial,
		fill: scss.colorWhite,
	});

	const [visualState, setVisualState] = useState<VisualState>(props.startVisualState);

	useEffect(() => {
		const angle = (endAngle - startAngle) * animationAlpha + startAngle;
		const coordinates = polarToCartesian(50, 50, angle, scoreRadius);
		setBenchmarkCoordinates({ x: coordinates.x, y: coordinates.y });

		if (animationAlpha !== 1.0) {
			setBenchmarkColors({ stroke: scss.benchmarkStrokeInitial, fill: scss.colorWhite });
			setVisualState(props.startVisualState);
		} else {
			// Last iteration.
			setBenchmarkColors({
				stroke: angle > 0 ? stroke : scss.benchmarkStrokeInitial,
				fill: scss.colorWhite,
			});
			setVisualState(props.endVisualState);
		}
	}, [animationAlpha]);

	useEffect(() => {
		if (animationAlpha === 1.0) {
			if (tooltipActive) {
				setBenchmarkColors({ stroke: scss.benchmarkStrokeActive, fill: scss.benchmarkFillActive });
			} else {
				setBenchmarkColors({ stroke: stroke, fill: scss.colorWhite });
			}
		}
	}, [tooltipActive]);

	return (
		<g
			className={cn(
				scss.benchmark,
				visualState === VisualState.FADE_IN && scss.fadeIn,
				visualState === VisualState.FADE_OUT && scss.fadeOut
			)}
		>
			<circle
				cx={benchmarkCoordinates.x}
				cy={benchmarkCoordinates.y}
				r={radius}
				strokeWidth={strokeWidth}
				fill={benchmarkColors.fill}
				stroke={benchmarkColors.stroke}
			/>
			<path
				transform={`translate(${benchmarkCoordinates.x - 3.25}, ${benchmarkCoordinates.y - 3.25})`}
				fill="#3C485E"
				d="M1.9,0.4v2.3l0,0l2.3-1.2l0,1.2l2.3-1.2v1.2v3.4H0V0.4H1.9z"
			/>
		</g>
	);
}

interface TargetProgressProps {
	scoreRadius: number;
	startAngle: number;
	endAngle: number;
	animationAlpha: number;
}

function TargetProgress(props: TargetProgressProps): JSX.Element {
	const { scoreRadius, startAngle, endAngle, animationAlpha } = props;

	const [pathData, setPathData] = useState("");
	const [visualState, setVisualState] = useState<VisualState>(VisualState.UNDEFINED);

	useEffect(() => {
		const angle = Math.min((endAngle - startAngle) * animationAlpha + startAngle, 359);
		setPathData(describeArc(50, 50, 0, angle, scoreRadius));
		if (angle === 0) {
			setVisualState(VisualState.HIDDEN);
		} else {
			setVisualState(VisualState.UNDEFINED);
		}
	}, [animationAlpha, endAngle]);

	return (
		<path
			className={cn(visualState === VisualState.HIDDEN && scss.targetHidden)}
			d={pathData}
			fill="none"
			stroke="#0fa4d6"
			strokeLinecap="round"
			strokeWidth="1"
		/>
	);
}

interface TargetScopeProps {
	startAngle: number;
	endAngle: number;
	animationAlpha: number;
	startVisualState: VisualState;
	endVisualState: VisualState;
}

function TargetScope(props: TargetScopeProps): JSX.Element {
	const {
		startAngle: targetStartAngle,
		endAngle: targetEndAngle,
		animationAlpha: targetAnimationAlpha,
	} = props;

	const [targetAngle, setTargetAngle] = useState<number>(targetStartAngle);
	const [visualState, setVisualState] = useState<VisualState>(VisualState.UNDEFINED);

	useEffect(() => {
		const angle = (targetEndAngle - targetStartAngle) * targetAnimationAlpha + targetStartAngle;
		setTargetAngle(angle);

		if (targetAnimationAlpha !== 1.0) {
			setVisualState(props.startVisualState);
		} else {
			// Last iteration.
			setVisualState(props.endVisualState);
		}
	}, [targetAnimationAlpha, targetEndAngle]);

	return (
		<g
			className={cn(
				scss.target,
				visualState === VisualState.FADE_IN && scss.fadeIn,
				visualState === VisualState.FADE_OUT && scss.fadeOut
			)}
			transform={`rotate(${targetAngle}, 50, 50)`}
		>
			<g stroke="#585a5f" strokeWidth="1.244601">
				<circle cx="50" cy="6.845238" r="4.978336" fill="none" />
				<g strokeLinecap="round">
					<line x1="50" y1="0.622301" x2="50" y2="2.827699" />
					<line x1="50" y1="10.862777" x2="50" y2="13.068176" />
					<line x1="43.777135" y1="6.845238" x2="45.982534" y2="6.845238" />
					<line x1="54.017611" y1="6.845238" x2="56.223010" y2="6.845238" />
				</g>
			</g>
			<circle cx="50" cy="6.845238" r="2.022494" fill="#0fa4d6" />
		</g>
	);
}

export type CircularProgressContentProps = {
	title: string;
	scoreValue: number;
	delta?: { value: number; tooltipText: string; ariaLabel: string };
};

function CircularProgressContent(props: CircularProgressContentProps): JSX.Element {
	const { title, delta, scoreValue } = props;
	const locale = useFormattingLanguage();
	let contentBottom;
	if (delta !== undefined) {
		const deltaText = `${delta.value >= 0 ? "+" : "-"}${toFormattedNumberString({
			format: "number",
			number: Math.abs(delta.value),
			alwaysShowDigits: true,
			digits: 1,
			locale,
		})}`;
		contentBottom = (
			<InlineText className={cn(scss.contentBottom)}>
				<Tooltip
					variant={{ type: "text" }}
					aria-label={`${delta.ariaLabel} ${deltaText}`}
					content={delta.tooltipText}
					placement="right"
				>
					{deltaText}
				</Tooltip>
			</InlineText>
		);
	}

	return (
		<div className={scss.content}>
			<InlineText lineHeight="multi-line" className={scss.contentTop}>
				{title}
			</InlineText>
			<div className={scss.contentMiddle}>
				<div>
					<span className={scss.mainValue}>
						{toFormattedNumberString({
							format: "number",
							number: scoreValue,
							alwaysShowDigits: true,
							digits: 1,
							locale,
						})}
					</span>
					<InlineText> /100</InlineText>
				</div>
			</div>
			{contentBottom}
		</div>
	);
}

interface ContentTooltipProps {
	content: React.ReactChild;
	"aria-label": string;
	top: number;
	right: number;
	bottom: number;
	left: number;
	active: boolean;
	onMouseEnter: () => void;
	onMouseLeave: () => void;
}

function ContentTooltip(props: ContentTooltipProps): JSX.Element {
	const {
		content,
		"aria-label": ariaLabel,
		left,
		top,
		right,
		bottom,
		onMouseEnter,
		onMouseLeave,
	} = props;

	return (
		<div
			className={scss.tooltipContainer}
			style={{
				left: `${left}%`,
				top: `${top}%`,
				right: `${right}%`,
				bottom: `${bottom}%`,
			}}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<Popover
				className={scss.tooltipTrigger}
				popoverContent={(id) => (
					<div id={id}>
						<Content>{content}</Content>
					</div>
				)}
				buttonContent={null}
				hideChevron
				buttonProps={{ variant: "borderless", className: scss.tooltipButton }}
				noMinWidth={true}
				aria-label={ariaLabel}
			/>
		</div>
	);
}

function easeInOutQuart(x: number): number {
	return x < 0.5 ? 8 * x * x * x * x : 1 - Math.pow(-2 * x + 2, 4) / 2;
}

function polarToCartesian(
	centerX: number,
	centerY: number,
	angleInDegrees: number,
	radius: number
): Coordinates {
	const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians),
	};
}

function describeArc(
	x: number,
	y: number,
	startAngle: number,
	endAngle: number,
	radius: number
): string {
	const start = polarToCartesian(x, y, endAngle, radius);
	const end = polarToCartesian(x, y, startAngle, radius);

	const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

	const d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
		" "
	);

	return d;
}
